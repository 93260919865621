<template>
    <div class="self-stretch w-full input mb-2 border-b flex flex-col normal-case">
        <div class="flex py-6 pl-4 h-16 self-stretch w-full ">
            <!--
            <span v-if="!account && empty" class="text-gray-600">Awaiting name and email ...</span>
            <span v-else-if="empty" class="text-gray-600">{{ content.summary }}</span>
            <span v-else-if="loading" class="text-gray-600">Loading ...</span>
            <span v-else-if="hasGiftAid">{{ account ? content.giftAidUserDoesText : content.giftAidAppliesText }}</span>
            <span v-else>{{ account ? content.giftAidUserDoesNotText :  content.giftAidNotAppliedText }}</span>
            <span v-if="!empty && !loading" class="pr-4 text-blue-600 cursor-pointer" v-on:click="toggleShowMore">{{ toggleText }}</span>
        -->
            <input type="checkbox" class="mr-4" v-bind:checked="value" v-on:input="$emit('input', $event.target.checked)"/>
            <span class="text-gray-600">{{ account ? content.giftAidUserDoesText : content.giftAidAppliesText }}</span>
            <span class="flex-1" />
            <span class="pr-4 text-blue-600 cursor-pointer" v-on:click="toggleShowMore">{{ toggleText }}</span>
        </div>
        <div v-if="showMore" class="pb-6 pl-4 pr-4 flex flex-col">
            <span class="pb-3" v-html="content.summary"></span>
            <span class="text-xs" v-html="content.description"></span>
        </div>
        <div v-if="showMore" class="w-full">
            <div>
                <div class="pb-6 pl-4 pr-4 flex flex-col">
                    <span class="pb-3" v-html="content.declaration"></span>
                    <span class="text-xs" v-html="content.admission"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../EventBus'

import {fetchRecaptcha} from '../utils/recaptcha';

export default {
    props: {
        value: Boolean,
        email: String,
        details: Object,
        content: Object,
        user: Object,
        account: Boolean,
        prefill: Object,
        showHiddenFields: Boolean,
    },
    data() {
        return {
            showMore: false,
            hasGiftAid: false,
            showErrors: false,
            processing: false,
            submitted: false,
            error: false,
            loading: true,
            empty: true,
            current: 0,
            form: this.content.fields.map(field => ({...field})),
            recaptchaToken: '',
            existing: {},
        }
    },
    computed: {
        toggleText() {
            if (this.showMore)
                return 'hide';
            return 'more'
        },
        isLoggedIn() {
            return Object.keys(this.user).length > 0
        }
    },
    methods: {
        toggleShowMore() {
            console.log("Hit")
            this.showMore = !this.showMore
        },
    }
}
</script>
