<template>
    <div class="max-w-6xl mx-auto fixed bottom-0 md:mb-4 mx-6 md:rounded-lg border-2 border-gray-400 right-0 left-0 bg-gray-200 shadow py-2 cookie-banner" :class="{'cookie-banner-show': triggerShow}" style="z-index: 1000">
            <div class=" flex flex-col md:flex-row items-center">
                <div class="text-sm font-bold pt-1 md:py-0 px-4 lg:w-1/5 hidden">
                    {{ title }}
                </div>
                <div class="text-sm px-2 md:px-6 py-3 lg:w-4/5 text-base font-medium">
                    {{ message }}
                </div>
                <div class="flex flex-row-reverse md:flex-row items-baseline mt-2 md:mt-0 self-center md:self-auto text-center">
                    <div class="px-2 flex-1">
                        <a :href="action.link"><span class="whitespace-nowrap mb-0 mr-4 w-full md:w-max border-b text-sm border-gray-900 font-light" style="white-space: nowrap;">{{ action.linkText }}</span></a>
                    </div>
                    <div class="px-4 flex-1">
                        <span class="whitespace-nowrap cursor-pointer w-full md:w-max rounded-sm flex-1 text-sm uppercase font-bold text-sm bg-blue-600 text-white text-center px-3 py-3 md:px-8 md:py-4 flex justify-center items-center"  v-on:click="accept" style="white-space: nowrap;">{{ action.accept }}</span>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

export default {
    props: {
        title: String,
        message: String,
        action: Object,
        show: Boolean,
    },
    data() {
        return {
            triggerShow: false,
        }
    },
    watch: {
        show(val) {
            this.$nextTick(() => {
                console.log("TriggerShow Time!")
                this.triggerShow = val
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.triggerShow = this.show
        })
    },
    methods: {
        accept() {
            console.log("Dismiss cookies")
            this.triggerShow = false
            this.$emit('accept-cookies')
        }
    }
}
</script>

<style>

.cookie-banner {
    transform: translateY(120%);
    transition-duration: 1s;
}

.cookie-banner-show {
    transform: translateY(0%);
    animation: cookie-banner-showup 1s normal forwards;
}

@@keyframes cookie-banner-showup {
    0% {
        transform: translateY(120%);
    }

    100% {
        transform: translateY(0%);
    }

}
</style>
