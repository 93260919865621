<template>
<md-card>
  <md-card-actions>
    <div class="md-subhead">
      <span>Centered Slides</span>
      <span>（</span>
      <span>Slides 居中</span>
      <span>）</span>
    </div>
    <md-button class="md-icon-button" target="_blank" href="https://github.com/surmon-china/vue-awesome-swiper/blob/master/examples/13-centered.vue">
      <md-icon>code</md-icon>
    </md-button>
  </md-card-actions>
  <md-card-media>
    <!-- swiper -->
    <swiper :options="swiperOption">
      <swiper-slide>Slide 1</swiper-slide>
      <swiper-slide>Slide 2</swiper-slide>
      <swiper-slide>Slide 3</swiper-slide>
      <swiper-slide>Community Public Toilets</swiper-slide>
      <swiper-slide>Slide 5</swiper-slide>
      <swiper-slide>Slide 6</swiper-slide>
      <swiper-slide>Slide 7</swiper-slide>
      <swiper-slide>Slide 8</swiper-slide>
      <swiper-slide>Slide 9</swiper-slide>
      <swiper-slide>Slide 10</swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </md-card-media>
</md-card>
</template>

<script>
import {
  swiper,
  swiperSlide
} from 'vue-awesome-swiper'

export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: 30,
        loopedSlides: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  }
}
</script>

<style scoped>
.swiper-slide {
  width: max-content;
}

.swiper-slide:nth-child(2n) {
  width: max-content;
}

.swiper-slide:nth-child(3n) {
  width: max-content;
}
</style>
