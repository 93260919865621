<template>
    <video v-on:contextmenu.prevent="nop" :id="id" controls="controls" :controlslist="admin ? 'nodownload' : ''" class="mx-auto w-full relative" :poster="poster">
      <slot></slot>
    </video>
</template>

<script>
export default {
    props: {
        poster: String,
        admin: Boolean,
        id: String,
    },
    methods: {
        nop() {
            return false;
        }
    }
}
</script>
