<template>
<div class="flex flex-col w-full mx-auto">
  <div v-if="banner" class="my-2 flex w-full flex-col">
    <div class="mt-0  flex font-bold justify-center items-center relative z-20 w-full sm:w-auto">
        <div :class="'absolute left-0 border-top pl-2 text-base h-full flex items-center ' + (footer ? 'text-blue-500' : 'text-white')" style="">{{ symbol }}</div>
      <span v-on:click="showDonation" :class="donateButtonCls">{{ text.action.donate }}</span>
    </div>
    <div v-if="showLink" v-on:click="navigate" :class="linkButtonCls"><h5 class="mb-0 mr-4 border-b border-white">{{ linkText }}</h5> <img class="w-8" src="/assets/images/globals/more-arrow.svg" alt=""> </div>
  </div>
  <div v-if="!banner" class="my-2 flex w-full flex-col sm:flex-row">
    <div class="sm:ml-2 mt-4 sm:mt-0 flex-1 flex justify-center items-center relative z-20 w-full sm:w-auto">
      <span v-on:click="showDonation" :class="donateButtonCls">{{ text.action.donate }}</span>
      <span v-if="showLink" v-on:click="navigate" :class="linkButtonCls">{{ linkText }}</span>
    </div>
  </div>
  <div class="flex flex-col md:flex-row lg:flex-col justify-end items-end">
    <popup-modal ref="modal" v-if="donation" :show="donation" v-on:close="hideDonation" class="sample" v-on:mounted="loadEmbeds">
      <div class="max-w-lg w-screen px-6 pt-6 pb-16  bg-white">
        <div class="mb-6 border-b" v-if="!hideTitle">
          <h2 class="text-gray-800 text-center ">{{ title }}</h2>
        </div>
        <div id="idonate" ref="idonate" :data-idonate-embed="embed" :data-designation="project">
            <svg id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24.967 24.967" class="mr-4">
              <g data-name="Group 554" transform="translate(-129.283 0)">
                <path id="Path_14" data-name="Path 14" d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
                  transform="translate(349.182 98.262)" fill="#fff" />
                <path id="Path_15" data-name="Path 15" d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
                  transform="translate(326.478 121.162)" fill="#fff" />
                <path id="Path_16" data-name="Path 16" d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
                  transform="translate(349.573 131.234)" fill="#fff" />
                <path id="Path_17" data-name="Path 17" d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
                  transform="translate(359.45 121.162)" fill="#fff" />
              </g>
            </svg>
        </div>
      </div>
    </popup-modal>
    <popup-modal v-if="confirmation" :show="confirmation" v-on:close="hideConfirmation">
      <div class="max-w-lg w-screen px-6 py-6 bg-white confirmation">
        <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
          <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="124.878" height="118.319" viewBox="0 0 124.878 118.319">
            <g id="Group_632" data-name="Group 632" transform="translate(2698.52 -2981.882)">
              <path id="Path_303" data-name="Path 303" d="M112.22,71.349s4.925-19.859,6.386-27.029,7.76-7.517,9.844-6.071,4.63,38.489,4.63,43.239,1.25,10.927-5.393,17.684-16.271,16.256-18.923,18.865-6.928,5.811-6.858,11.161,0,10.078,0,10.078H74.227l2.13-32.111,25.55-15.987Z"
                transform="translate(-2706.814 2960.926)" fill="#3f9de5" />
              <path id="Path_302" data-name="Path 302" d="M98.861,102.515s-7,8.249-23.513,8.249c.112.04.838-7.583.838-7.583s.563-20.066,22.675-19.3c.235,0,13.574-16.97,13.574-16.97s11.185,6.919,2.868,16.97S98.861,102.515,98.861,102.515Z" transform="translate(-2706.66 2964.939)"
                fill="#2f83c3" />
              <path id="Path_304" data-name="Path 304" d="M95.18,71.349S90.255,51.49,88.794,44.32s-7.76-7.517-9.844-6.071-4.63,38.489-4.63,43.239-1.25,10.927,5.393,17.684,16.271,16.256,18.923,18.865,6.928,5.811,6.858,11.161,0,10.078,0,10.078h27.679l-2.13-32.111-25.55-15.987Z"
                transform="translate(-2772.746 2960.926)" fill="#3f9de5" />
              <path id="Path_305" data-name="Path 305" d="M94.866,102.515s7,8.249,23.513,8.249c-.112.04-.838-7.583-.838-7.583s-.563-20.066-22.675-19.3c-.235,0-13.574-16.97-13.574-16.97s-11.185,6.919-2.868,16.97S94.866,102.515,94.866,102.515Z"
                transform="translate(-2759.227 2964.939)" fill="#2f83c3" />
              <ellipse id="Ellipse_304" data-name="Ellipse 304" cx="30.693" cy="30.693" rx="30.693" ry="30.693" transform="translate(-2666.682 2981.882)" fill="#f5f5f5" />
              <path id="Path_306" data-name="Path 306" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" transform="translate(-568 272)" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
          </svg>
          <h3 class="text-center">{{ text.messages.received.title }}</h3>
          <p class="text-center">{{ text.messages.received.desc }}</p>
          <div v-if="chainSignup && !user.firstName">
              <p>Please consider partnering with us by signing up for an account.</p>
              <span v-on:click="showSignup" :class="donateButtonCls">Complete Signup</span>
          </div>
        </div>
      </div>
    </popup-modal>
    <popup-modal v-if="error" :show="error" v-on:close="hideError">
      <div class="flex flex-col items-cewnter justify-center max-w-lg w-screen px-6 py-6  bg-white">
        <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
          <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="119.455" height="119.455" viewBox="0 0 119.455 119.455">
            <g id="Group_634" data-name="Group 634" transform="translate(2880.717 -2986.847)">
              <circle id="Ellipse_305" data-name="Ellipse 305" cx="59.728" cy="59.728" r="59.728" transform="translate(-2880.717 2986.847)" fill="#f5f5f5" />
              <g id="Group_633" data-name="Group 633" transform="translate(9.682 8.682)">
                <line id="Line_191" data-name="Line 191" x2="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                <line id="Line_192" data-name="Line 192" x1="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
              </g>
            </g>
          </svg>
          <h3 class="text-center">{{ text.messages.error.title }}</h3>
          <p class="text-center">{{ text.messages.error.desc }}</p>
          <a class="mt-8 btn" href="mailto:it@empart.org.au">{{ text.action.contact }}</a>
        </div>
      </div>
    </popup-modal>
  </div>
  <popup-modal :show="signup" v-on:close="hideSignup">
      <user-signup :prefill="captured" class="popup-sign-up bg-white px-6 py-8" />
  </popup-modal>
</div>
</template>

<script>
import EventBus from '../EventBus'

export default {
  props: {
    text: Object,
    title: String,
    hideTitle: Boolean,
    project: String,
    embed: String,
    handle: String,
    footer: Boolean,
    dark: Boolean,
    primary: Boolean,
    banner: Boolean,
    user: Object,
    showLink: Boolean,
    linkText: String,
    linkUrl: String,
    remote: Boolean,
  },
  data() {
    return {
      signup: false,
      donation: false,
      confirmation: false,
      error: false,
      captured: {},
    }
  },
  created() {
    this.handle = this.handle.replace(".", "");
  },
  computed: {
    donateButtonCls() {
      if (this.footer) {
        return this.primary ? "donate-field cursor-pointer rounded-sm flex-1 bg-blue-600 uppercase text-sm border-2 border-blue-600 text-white text-center px-2 h-12 flex justify-center items-center" :
        "cursor-pointer rounded-sm flex-1 bg-white uppercase text-sm brand-text border-2 border-white text-white text-center px-2 h-12 flex justify-center items-center";
      } else if (this.showLink) {
        return "donate-field cursor-pointer w-1/2 rounded-sm flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 h-12 flex justify-center items-center mr-1";
      } else {
        return "donate-field cursor-pointer w-1/2 rounded-sm flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 h-12 flex justify-center items-center";
      }
    },
    linkButtonCls() {
      if (this.footer) {
        return "cursor-pointer rounded-sm flex-1 bg-transparent uppercase text-sm border border-white text-white text-center px-2 h-12 flex justify-center items-center";
      } else {
        return "cursor-pointer rounded-sm w-full font-bold bg-transparent text-base text-white text-center px-2 flex justify-center sm:justify-start items-center mt-6";
      }
    },
  },
  methods: {
    showDonation() {
      this.donation = true;
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    loadEmbeds() {
        this.$nextTick(() => {
            EventBus.$emit('craft:load-embeds', {
                embed: this.embed,
                target: "idonate",
            });
        })
    },
    hideDonation() {
      this.donation = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    showConfirmation(ev) {
      this.hideDonation();
      this.captured = ev.values;
      this.confirmation = true;
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    showError(ev) {
      this.hideDonation();
      this.error = true;
      EventBus.$emit('craft:noscroll', this.$refs.modal)
    },
    hideConfirmation() {
      this.confirmation = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    hideError() {
      this.error = false;
      EventBus.$emit('craft:scroll', this.$refs.modal)
    },
    navigate() {
      window.location = this.linkUrl
    },
    showSignup() {
        this.hideConfirmation();
        this.signup = true;
    },
    hideSignup() {
        this.signup = false;
    }
  },
};
</script>
