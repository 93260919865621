<template>
<div :class="' fixed inset-0 flex z-50 items-center justify-center ' + (show ? 'fixed' : 'hidden')" style="background:#0003; z-index:100;">
    <div class="popup-contain relative h-full sm:h-auto">
        <div class="h-12">
            <div v-on:click="() => onClose()" class="point close bg-gray-100 absolute left-0 text-center right-0 mx-auto">{{ close || 'Close' }}</div>
        </div>
        <div v-on:click.stop v-body-scroll-lock="show" class="max-w-2xl mx-auto shadow-md popup-inner overflow-y-scroll overflow-x-hidden relative max-h-modal" v-if="show">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['show', 'close'],
    mounted() {
        this.$emit('mounted');
    },
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
}
</script>
