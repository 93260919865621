<template>
    <div ref="content">
        <slot>
        </slot>
        <div v-if="error" class="text-sm px-4 py-2 bg-red-500 text-white" v-text="error"></div>
    </div>
</template>

<script>

export default {
    props: {
        safeDomains: Array,
        tag: {
          type: String,
          default: 'script'  
        },
    },
    data() {
        return {
            error: '',
        }
    },
    mounted() {
        console.log("Mounting embed")
        console.log(this.safeDomains)
        if (this.$refs.content) {
            const script = this.$refs.content.querySelector(this.tag);

            //No script
            if (!script) return;

            if (this.isSafeDomain(script.dataset.src)) {
                script.setAttribute('src', script.dataset.src);
            } else {
                this.error = `Embedded script for ${script.dataset.src} blocked: bad domain`
                script.remove();
            }
        }
    },
    methods: {
        isSafeDomain(url) {
            return this.safeDomains.includes(new URL(url).hostname)
        }
    }
}
</script>
