<template>
<div>
    <div :class="buttonStyle" v-on:click="reveal">{{ title }}</div>
    <popup-modal ref="modal" :show="showModal" v-on:close="hide" :close="close">
        <slot></slot>
    </popup-modal>
</div>
</template>

<script>
import PopupModal from './PopupModal'
import EventBus from '../EventBus'

export default {
    props: {
        title: String,
        show: Boolean,
        close: String,
        buttonStyle: String,
    },
    components: {
        PopupModal,
    },
    data() {
        return {
            showModal: this.show,
        }
    },
    methods: {
        reveal() {
            this.showModal = true
            EventBus.$emit('craft:noscroll', this.$refs.modal)
        },
        hide() {
            this.showModal = false
            EventBus.$emit('craft:scroll', this.$refs.modal)
        },
    }
}
</script>
