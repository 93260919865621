<template>
<div class="w-full flex flex-col align-stretch mt-8 px-4">
  <div v-for="donation in history" class="mb-4 items-start text-left flex items-center border-b ">
    <div class="w-1/3 py-4 ">
      <div class="mb-0 pl-4 text-sm font-normal text-gray-600 ">{{ ago(donation.when) }}</div>
    </div>
    <div class="w-1/3 py-4">
      <h4 class="mb-0 pl-4 font-normal">${{ pretty(donation.amount) }}</h4>
    </div>
    <div class="w-1/3">
      <h5 class="mb-0 pl-4 text-sm font-normal text-gray-00">{{ donation.name }} </h5>
    </div>

  </div>
</div>
</template>

<script>
import EventBus from '../EventBus';

export default {
  props: {
    fundraiserId: String,
  },
  data() {
    return {
      loaded: false,
      tracked: 0,
      history: []
    }
  },
  beforeMount() {
    EventBus.$on('craft:fundraising-tracking', this.tracking);
  },
  computed: {
    percent() {
      if (this.loaded) {
        return (Math.min(this.tracked.total / 100.0, this.target) / this.target * 100).toFixed(0);
      }
      return 0;
    }
  },
  methods: {
    tracking(data) {
      console.log(data, this.fundraiserId);
      this.loaded = true;
      if (data[this.fundraiserId]) {
        this.tracked = data[this.fundraiserId]
        this.history = this.tracked.history
      }
    },
    ago(ts) {
      let now = +new Date() / 1000;
      let seconds, minutes, hours, days, weeks;
      seconds = (now - ts);
      minutes = seconds / 60;
      hours = minutes / 60;
      days = hours / 24;
      weeks = days / 7;

      if (minutes < 5) {
        return 'just now'
      } else if (hours < 1) {
        return `${Math.floor(minutes)}m ago`
      } else if (days < 1) {
        return `${Math.floor(hours)}h ago`
      } else if (weeks < 1) {
        return `${Math.floor(days)}d ago`
      } else {
        return `${Math.floor(weeks)}w ago`
      }
    },
    pretty(amount) {
      return (amount / 100.0).toFixed(0);
    }
  }
}
</script>
