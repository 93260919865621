<template>
<div ref="outer" class="overflow-hidden relative">
  <!-- <donation-nav class="w-full"></donation-nav> -->
  <div class="w-full border-b project-nav point">
    <swiper :options="swiperOption" ref="swiperTop">
      <swiper-slide class="py-8 " v-for="(slide, index) in trimSlides" :key="slide.title">
        <span class="text-blue-600 font-bold flex flex-wrap justify-center ind-slide">{{ slide.title }}</span>
      </swiper-slide>
    </swiper>
    <!-- <hooper class="text-center" ref="carousel" @slide="updateCarousel" :settings="hooperSettings">
      <slide class="text-centers" v-for="(slide, index) in trimSlides" :key="slide.title">
        <div class="w-full flex flex-wrap justify-center py-8 text-blue-600 font-bold">
          <span>{{ slide.title }}</span>
        </div>
      </slide>
      <navigation class="absolute top-0 bottom-0 w-full" slot="hooper-addons"></navigation>

    </hooper> -->
  </div>
  <swiper ref="swiperThumbs" class="container md:py-12" :options="swiperOptionThumbs">
    <slot></slot>
    <!--<swiper-slide v-for="(slide, index) in trimSlides" :key="slide.title">
      <div class="w-full flex flex-wrap ">
        <div class="w-full md:w-1/2 h-full">
          <div class="bg-grey-lighter md:h-full w-full md:flex-1 bg-cover bg-center bg-no-repeat img-height" :style="'background-image:url('+slide.image+')'">
          </div>
        </div>
        <div class="w-full md:w-1/2 p-12 flex flex-col justify-center">
          <div class="" v-html="slide.description">
          </div>
          <div class="italic small pt-2" v-html="slide.deductible"></div>
        </div>
      </div>
    </swiper-slide>-->

  </swiper>
  <div class="lg:block hidden">
    <div class="swiper-button-prev ml-6 "></div>
    <div class="swiper-button-next mr-6"></div>
  </div>

</div>
</template>

<script>
import {
  swiper,
  swiperSlide
} from 'vue-awesome-swiper'

export default {
  props: ['slides'],
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop: true,
        preventClicks: false,
        slideToClickedSlide: true,
        spaceBetween: 60,
        loopedSlides: 10,
        threshold: 5,
        centeredSlides: true,
        simulateTouch: true,
        slideToClickedSlide: true,
        mousewheel: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        effect: 'fade',
        slidesPerView: "1",
        mousewheel: true,
        loop: true,
        loopedSlides: 10, //looped slides should be the same
        slideToClickedSlide: true,
      },
      currentSlide: 0,
      trimSlides: this.slides.map(({
        title,
        image,
        description,
        deductible
      }) => {
        console.log(description.slice(1, -2));
        return {
          title: title.slice(1, -1),
          image,
          description: description.slice(1, -2),
          deductible
        }
      })
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  }
}
</script>


<style scoped>
.swiper-slide {
  width: max-content;
}

.swiper-slide:nth-child(2n) {
  width: max-content;
}

.swiper-slide:nth-child(3n) {
  width: max-content;
}
</style>
