<template>
    <div class="text-center text-white inner flex flex-col items-center py-12 counter px-8">
      <div class="pb-6">
            <div class="text-5xl lg:text-6xl">{{ counter  }}</div>
      </div>
        <div v-observe-visibility="detect" class="text-2xl lg:text-3xl font-bold flex-1 flex">
                <span>{{ text  }}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        text: String,
        total: Number,
        rate: Number,
        refresh: Number,
    },
    data() {
        return {
            counter: 0,
            last: 0,
        }
    },
    methods: {
        count() {
            if (this.counter < this.total) {
                this.counter += Math.ceil((this.total - this.counter)*(this.rate ? 1/this.rate : 0.1));
                window.requestAnimationFrame(this.count);
            } else {
                this.last = Math.floor(Date.now() / 1000);
            }
        },
        detect(visible) {
            console.log("Change vis ", visible)
            if (visible) {
                let now = Math.floor(Date.now() / 1000);
                if (this.counter == this.total) {
                    if (this.refresh && this.last + this.refresh < now) {
                        this.counter = 0;
                        requestAnimationFrame(this.count);
                    }
                }
                else
                    requestAnimationFrame(this.count);
            }
        }
    },
}
</script>
