<template>
<div>
    <div :class="' fixed inset-0 flex z-50 items-center justify-center ' + (submitting ? 'fixed' : 'hidden')" style="background:#0003; z-index:110;">
        <span class="">{{ text.messages.formSubmittingText }}</span>
    </div>
    <div v-if="!submitted" class="text-center">
        <div class="mx-3 mt-6 mb-4 text-left" style="text-transform:none; line-height:1rem;" v-if="text.messages.formPreamble" v-html="text.messages.formPreamble"></div>
        <div v-for="section in sections">
            <h3 class="capitalize" :class="{'mt-4': showGiftaid}">{{ section.label }}</h3>
            <div v-for="field of section.fields" :key="field.name">
                <input v-if="(['text', 'password', 'file', 'email', 'hidden', 'textarea']).includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="field.label"
                    class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :class="{'border-red-500': fieldErrors[field.name] || submitErrors[field.name]}"/>
                <select v-if="field.type === 'select'" class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input w-full"  v-model="field.value" :name="field.name" :placeholder="field.label" v-on:change="ev => field.name == 'countrySelect' && changeCountry(ev)" >
                    <option v-for="option of field.options" :value="option.value">{{ option.label }}</option>
                </select>
                <div v-if="field.type === 'checkbox'" style="text-transform: none">
                  <div class="self-stretch w-full py-2 pl-4 input mb-2 border-b flex items-center" :class="{'border-red-500':fieldErrors[field.name] || submitErrors[field.name],'border-gray-200': !fieldErrors[field.name] && !submitErrors[field.name]}">
                      <div class="text-xs text-left">{{ field.instructions }}</div>
                      <div class="flex mx-4 text-sm">
                        <input v-model="field.value" :type="field.type" :id="'form-input-' + field.handle" :placeholder="field.label" :class="{'border-red-500 mr-2':fieldErrors[field.name] || submitErrors[field.name],'border-gray-200 mr-2': !fieldErrors[field.name] && !submitErrors[field.name], required:field.required}" />
                        <label>{{ field.label }}</label>
                      </div>
                  </div>
                </div>
                <div v-if="field.type === 'row'" class="flex">
                    <template v-for="(ff, index) of field.fields">
                        <input v-if="(['text', 'password', 'file', 'email', 'hidden', 'textarea']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="ff.label"
                            class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'border-red-500': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                        <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input flex-1" v-on:change="ev => ff.name == 'countrySelect' && changeCountry(ev)" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="ff.label" :key="ff.name">
                            <option v-for="option of ff.options" :value="option.value">{{ option.label }}</option>
                        </select>
                        <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                        <div v-if="ff.type === 'checkbox'" style="text-transform: none">
                          <div class="self-stretch w-full h-16 py-6 pl-4 input mb-2 border-b flex items-center" :class="{'border-red-500':fieldErrors[ff.name] || submitErrors[ff.name],'border-gray-200': !fieldErrors[ff.name] && !submitErrors[ff.name]}">
                              <div class="text-xs text-left">{{ ff.instructions }}</div>
                              <div class="flex mx-4 text-sm">
                                <input v-model="ff.value" :type="ff.type" :id="'form-input-' + ff.handle" :placeholder="ff.label" :class="{'border-red-500 mr-2':fieldErrors[ff.name] || submitErrors[ff.name],'border-gray-200 mr-2': !fieldErrors[ff.name] && !submitErrors[ff.name], required:ff.required}" />
                                <label>{{ ff.label }}</label>
                              </div>
                          </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!--<h3 class="capitalize mt-6">Contact</h3>
        <div v-for="field of contactFields" :key="field.name">
            <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :key="ff.name" :class="{'border-red-500': fieldErrors[field.name] || submitErrors[field.name]}" />
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option of field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
            <div v-if="field.type === 'row'" class="flex">
                <template v-for="(ff, index) of field.fields">
                    <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="t(ff)"
                        class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'border-red-500': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                    <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="t(ff)" :key="ff.name">
                        <option v-for="option of ff.options" :value="option">{{ ff.mapping[option] }}</option>
                    </select>
                    <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                </template>
            </div>
        </div>

        <h3 class="capitalize mt-6">Address</h3>
        <div v-for="field of addressFields" :key="field.name">
            <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" :class="{'border-red-500': fieldErrors[field.name] || submitErrors[field.name]}" />
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option of field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
            <div v-if="field.type === 'row'" class="flex">
                <template v-for="(ff, index) of field.fields">
                    <input v-if="(['text', 'password', 'file', 'email', 'hidden']).includes(ff.type)" :autocomplete="ff.type === 'password' ? 'new-password' : 'off'" :type="ff.type" v-model="ff.value" :name="ff.name" :placeholder="t(ff)"
                        class="my-2 border-b py-6 pl-4 h-16 text-sm input flex-1" :key="ff.name" :class="{'border-red-500': fieldErrors[ff.name] || submitErrors[ff.name]}" />
                    <select class="my-2 border-b py-6 w-24 pl-4 h-16 text-sm input" v-on:change="ev => ff.name == 'fields[country]' && changeCountry(ev)" v-if="ff.type === 'select'" v-model="ff.value" :name="ff.name" :placeholder="t(ff)" :key="ff.name">
                        <option v-for="option of ff.options" :value="option">{{ ff.mapping[option] }}</option>
                    </select>
                    <span class="w-4" v-if="index < field.fields.length-1" :key="ff.name + '-space'" />
                </template>
            </div>
        </div>-->


        <div  class="mx-3 mt-6 mb-4 text-left" style="text-transform:none; line-height:1rem;" v-if="text.visibility.privacy" v-html="text.messages.linkToPrivacyPolicyText"></div>

        <div class="flex flex-strech">
            <span v-on:click="submit" class="flex justify-center items-center cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-4">
                <svg v-if="submitting" id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24.967 24.967" class="mr-4">
                    <g data-name="Group 554" transform="translate(-129.283 0)">
                        <path id="Path_14" data-name="Path 14"
                            d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
                            transform="translate(349.182 98.262)" fill="#fff" />
                        <path id="Path_15" data-name="Path 15"
                            d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
                            transform="translate(326.478 121.162)" fill="#fff" />
                        <path id="Path_16" data-name="Path 16"
                            d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
                            transform="translate(349.573 131.234)" fill="#fff" />
                        <path id="Path_17" data-name="Path 17"
                            d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
                            transform="translate(359.45 121.162)" fill="#fff" />
                    </g>
                </svg>
                {{submitting ? text.processing : text.submit }}
            </span>
        </div>
        <div v-if="showErrors && fieldErrorList.length" class="text-sm text-red-500 mt-2 leading-none">
            <span>{{ text.messages.formErrorMissingFields }}</span>
            <span class="text-xs uppercase font-medium" v-for="field, index in fieldErrorList" v-if="field.name && fieldErrors[field.name]">{{ t(field) }}<span v-if="index < fieldErrorList.length -1">, </span></span>
        </div>
        <div v-if="submitErrorMessages.length" class="text-sm normal-case text-red-500 mt-2 leading-none">
            <div v-for="error in submitErrorMessages">{{ error }}</div>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal " v-if="submitted && connection">
        <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="119" height="129.053" viewBox="0 0 119 129.053">
            <g id="Group_630" data-name="Group 630" transform="translate(2128.5 -2697.739)">
                <path id="Path_288" data-name="Path 288" d="M-1968.648,2810.125l57.008-34.723,57.227,34.723Z" transform="translate(-157 -76.163)" fill="#2e5492" stroke="#2e5492" stroke-linejoin="round" stroke-width="3" />
                <rect id="Rectangle_1326" data-name="Rectangle 1326" width="116" height="91" transform="translate(-2127 2734)" fill="#4049b2" stroke="#4049b2" stroke-linejoin="round" stroke-width="3" />
                <rect id="Rectangle_1327" data-name="Rectangle 1327" width="111" height="85" rx="7" transform="translate(-2124 2711)" fill="#f5f5f5" />
                <path id="Path_286" data-name="Path 286" d="M-1968.648,2829.134l42.755-53.732,42.919,53.732Z" transform="translate(703.122 4707.571) rotate(90)" fill="#1875bc" stroke="#1875bc" stroke-linejoin="round" stroke-width="3" />
                <path id="Path_287" data-name="Path 287" d="M-1968.648,2829.134l42.755-53.732,42.919,53.732Z" transform="translate(-4840.415 855.949) rotate(-90)" fill="#1875bc" stroke="#1875bc" stroke-linejoin="round" stroke-width="3" />
                <path id="Path_289" data-name="Path 289" d="M-1968.388,2823.088l50.957-47.234,6.966,5.482,7.23-5.482,50.523,47.234Z" transform="translate(-158.352 1.266)" fill="#14629d" />
                <path id="Path_285" data-name="Path 285" d="M-1968.648,2821.027l58.075-45.064,57.925,45.064Z" transform="translate(-158.352 4.266)" fill="#3f9de5" stroke="#3f9de5" stroke-linejoin="round" stroke-width="3" />
                <path id="Path_290" data-name="Path 290" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
            </g>
        </svg>

        <h3 class="text-center">{{ text.messages.signedUpTitle }}</h3>
        <p class="text-center">{{ text.messages.signedUpDescription }}</p>
    </div>
    <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal " v-if="submitted && !connection">
      <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="124.878" height="118.319" viewBox="0 0 124.878 118.319">
        <g id="Group_632" data-name="Group 632" transform="translate(2698.52 -2981.882)">
          <path id="Path_303" data-name="Path 303" d="M112.22,71.349s4.925-19.859,6.386-27.029,7.76-7.517,9.844-6.071,4.63,38.489,4.63,43.239,1.25,10.927-5.393,17.684-16.271,16.256-18.923,18.865-6.928,5.811-6.858,11.161,0,10.078,0,10.078H74.227l2.13-32.111,25.55-15.987Z"
            transform="translate(-2706.814 2960.926)" fill="#3f9de5" />
          <path id="Path_302" data-name="Path 302" d="M98.861,102.515s-7,8.249-23.513,8.249c.112.04.838-7.583.838-7.583s.563-20.066,22.675-19.3c.235,0,13.574-16.97,13.574-16.97s11.185,6.919,2.868,16.97S98.861,102.515,98.861,102.515Z" transform="translate(-2706.66 2964.939)"
            fill="#2f83c3" />
          <path id="Path_304" data-name="Path 304" d="M95.18,71.349S90.255,51.49,88.794,44.32s-7.76-7.517-9.844-6.071-4.63,38.489-4.63,43.239-1.25,10.927,5.393,17.684,16.271,16.256,18.923,18.865,6.928,5.811,6.858,11.161,0,10.078,0,10.078h27.679l-2.13-32.111-25.55-15.987Z"
            transform="translate(-2772.746 2960.926)" fill="#3f9de5" />
          <path id="Path_305" data-name="Path 305" d="M94.866,102.515s7,8.249,23.513,8.249c-.112.04-.838-7.583-.838-7.583s-.563-20.066-22.675-19.3c-.235,0-13.574-16.97-13.574-16.97s-11.185,6.919-2.868,16.97S94.866,102.515,94.866,102.515Z"
            transform="translate(-2759.227 2964.939)" fill="#2f83c3" />
          <ellipse id="Ellipse_304" data-name="Ellipse 304" cx="30.693" cy="30.693" rx="30.693" ry="30.693" transform="translate(-2666.682 2981.882)" fill="#f5f5f5" />
          <path id="Path_306" data-name="Path 306" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" transform="translate(-568 272)" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
        </g>
      </svg>
      <h3 class="text-center">{{ text.messages.signedUpTitle }}</h3>
      <p class="text-center">{{ text.messages.signedUpDescription }}</p>
    </div>
</div>
</template>

<script>
import EventBus from '../EventBus';
//import {countries, countryMap} from '../utils/countries';

import {fetchRecaptcha} from '../utils/recaptcha';

/*
const fieldMap = [{
        name: "firstName",
        text: "firstName",
        title: "First Name",
        default: '',
        type: "text",
    },
    {
        name: "lastName",
        text: "lastName",
        title: "Last Name",
        default: '',
        type: "text",
    },
    {
        name: "password",
        text: "password",
        title: "Password",
        default: '',
        type: "password",
    },
    {
        name: "email",
        title: "Email",
        default: '',
        type: "row",
        fields: [{
                name: "fields[emailType]",
                text: "emailType",
                title: "Email Type",
                default: 'Home Email',
                type: "select",
                options: [
                    "Home Email",
                    "Work Email",
                    "Other Email"
                ],
                mapping: {
                    "Home Email": "personal",
                    "Work Email": "work",
                    "Other Email": "other"
                }
            },
            {
                name: "email",
                text: "email",
                title: "Email",
                default: '',
                type: "email",
            }
        ]
    },
    {
        name: "phone",
        title: "Phone",
        default: '',
        type: "row",
        fields: [{
                name: "fields[phoneType]",
                title: "Phone Type",
                text: "phoneType",
                default: 'Mobile Phone',
                type: "select",
                options: [
                    "Home Phone",
                    "Mobile Phone",
                    "Work Phone",
                    "Other Phone",
                ],
                mapping: {
                    "Home Phone": "home",
                    "Work Phone": "work",
                    "Mobile Phone": "mobile",
                    "Other Phone": "other"
                }
            },
            {
                name: "fields[phone]",
                title: "Phone",
                text: "phone",
                default: '',
                type: "text",
            },
        ]
    },
    {
        name: "fields[contactType]",
        title: "Account Type",
        text: "contactType",
        type: "select",
        default: "Household",
        options: [
            "Household",
            "Business",
        ],
        mapping: {
            Household: "Personal",
            Business: "Business",
            Church: "Church",
            Organization: "Organization"
        },
    },
    {
        name: "fields[street]",
        default: '',
        text: "street",
        title: "Address",
        type: "text",
    },
    {
        name: "fields[address2]",
        text: "address2",
        default: '',
        optional: true,
        title: "Address (if needed)",
        type: "text",
    },
    {
        name: "fields[suburb]",
        text: "suburb",
        default: '',
        title: "Suburb",
        type: "text",
    },
    {
        name: "fields[state]",
        text: "state",
        default: '',
        title: "State/Province",
        type: window.userform && window.userform.visibility && window.userform.visibility.state ? "select" : "hidden",
        options: [],
        optional: window.userform && window.userform.visibility && window.userform.visibility.state,
        mapping: {},
    },
    {
        type: 'row',
        name: 'post',
        fields: [{
                name: "fields[country]",
                default: window.countryCode,
                title: "Country",
                text: "country",
                type: "select",
                //options: countries,
                //mapping: countryMap,
            },
            {
                name: "fields[postCode]",
                default: '',
                text: "postCode",
                title: "Postcode",
                type: "text",
            },
        ]
    },
    {
        type: 'hidden',
        default: '1',
        title: 'Sync Virtuous',
        name: 'fields[syncVirtuous]',
    },
    {
        type: 'hidden',
        default: window.siteId,
        title: 'Site ID',
        name: 'fields[siteId]',
    }
]*/

export default {
    props: {
        prefill: {
            type: Object,
            default: () => ({})
        },
        privacyUrl: String,
        showGiftaid: Boolean,
        giftaid: Object,
        text: Object,
        connection: Boolean,
    },
    data() {
        return {
            ...window.accountSignUpForm,
            fields: window.accountSignUpForm.layout[0].reduce((prev, row) => {
                row.columns.forEach(hash => {
                    if (window.accountSignUpForm.fields[hash])
                        prev[window.accountSignUpForm.fields[hash].handle] = window.accountSignUpForm.fields[hash]
                })
                return prev
            }, {}
            ), /*fieldMap.reduce((prev, field) => ({
                ...prev,
                [field.name]: field.type === 'row' ? {
                    ...field,
                    fields: field.fields.map((ff) => ({
                        ...ff,
                        value: this.prefill[field.name] || ff.default,
                    }))
                } : {
                    ...field,
                    value: this.prefill[field.name] || field.default,
                }
            }), {}),*/
            hiddenFields: window.accountSignUpForm.hiddenFields,
            submitted: false,
            submitting: false,
            showErrors: false,
            submitErrors: {},
            submitErrorMessages: [],
            giftAidDetails: {},
        }
    },
    created() {
        console.log(this.values)
    },
    mounted() {
        this.$nextTick(() => EventBus.$emit('craft:fetch-state', {code: window.countryCode, callback: this.updateStates}))
    },
    computed: {
        allFieldsFlattened() {
            let result = []
            this.sections.forEach((section) => {
                section.fields.forEach((field) => {
                    if (field.type === 'row') {
                        result = [
                            ...result,
                            ...field.fields
                        ]
                    } else {
                        result.push(field)
                    }
                })
            })

            return result
        },
        sections() {
            let current;
            let output = [];
            let section = ""

            for (let row of this.layout[0]) {
                if (!window.accountSignUpForm.fields[row.columns[0]])
                    continue;
                let currentSection = window.accountSignUpForm.fields[row.columns[0]].section
                if (currentSection && currentSection !== section) {
                    current = {
                        label: currentSection,
                        fields: [],
                    }
                    section = currentSection
                    output.push(current)
                }

                if (row.columns.length > 1) {
                    current.fields.push({
                        type: 'row',
                        fields: row.columns.map((hash) => window.accountSignUpForm.fields[hash])
                    })
                } else {
                    current.fields.push(window.accountSignUpForm.fields[row.columns[0]])
                }
            }

            return output
        },
        fieldErrors() {
            let errors = {}

            if (!this.showErrors)
                return errors;

            this.allFieldsFlattened.map(({
                name,
                value,
                type,
                required,
                instructions,
                fields
            }) => {
                if (type === 'row') {
                    fields.map((ff) => {
                        if (!ff.value && ff.required) {
                            errors[ff.name] = "Field is empty"
                        }
                    })
                } else if (!value && required) {
                    errors[name] = "Field is empty"
                }
            })

            return errors;
        },
        fieldErrorList() {
            return this.allFieldsFlattened.filter((field) => this.fieldErrors[field.name])
        },
    },
    methods: {
        submit() {
            this.showErrors = true;
            if (this.submitting)
                return;
            this.submitting = true;
            if (Object.keys(this.fieldErrors).length) {
                console.log("Incomplete fields")
                this.submitting = false;
                return;
            }
            console.log("Checking recaptcha");
            fetchRecaptcha().then(token => {
                console.log(token)
                let data = this.buildData(token);
                EventBus.$emit('craft:post', {
                    callback: this.postResponse,
                    action: this.action,
                    override: window.location,
                    headers: {
                        Accept: '*/*',
                        'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    data
                })
            })

        },
        changeCountry(ev) {
            EventBus.$emit('craft:fetch-state', {code: ev.target.value, callback: this.updateStates})
        },
        updateStates(states) {
            const options = states.map(({value, title}) => ({value, label: title}));
            if (this.fields.state) {
                this.fields['state'].options = options;
                this.fields['state'].value = options[0].value;
            }
        },
        async postResponse(resp) {
            this.submitting = false
            try {
                let data = await resp.json();
                if (resp.ok) {

                   if (data.formErrors.length) {
                       this.processing = false;
                       this.submitErrorMessages = data.formErrors
                       return
                   }
                  this.submitted = true;

                  console.log(resp)
                  this.$emit('success', {
                    resp,
                    values: this.buildData(),
                  })
                } else {
                    this.$emit('failed', {
                      resp,
                    })
                }
            }
            catch (error) {
                this.$emit('failed', {
                    resp
                })
            }
        },
        t(field) {
            return this.text[field.text] || field.title
        },
        buildData(recaptchaToken) {
            let data = {};

            this.allFieldsFlattened.concat(this.hidden).forEach(({handle, value}) => {
                data[handle] = value
            })

            data['formHash'] = this.hash
            data[window.csrfTokenName] = window.csrfTokenValue;
            data['g-recaptcha-response'] = recaptchaToken
            return data
        },
    }
}
</script>
