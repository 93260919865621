<template>
<div ref="outer" class="overflow-hidden main-slider">
  <div class="w-full bg-gray-900">
    <div class="container pt-16 mx-auto lg:pt-40 text-white top-piece inner flex flex-wrap mx-auto;">
      <div class="lg:w-1/3 md:w-1/2 w-full pr-0 xl:pr-6 md:pr-2">
        <h2>{{ heading }}</h2>
      </div>
      <div class="w-full md:w-1/2 mt-8 md:mt-0 md:pl-16 lg:pl-24">
        <ul class="flex flex-wrap mt-2">
          <li v-for="(slide, index) in trimSlides" :key="slide.title" :class="'w-1/2 underline point ' + (index === currentSlide ? 'font-bold' : '')" v-on:click="(ev) => slideTo(ev, index)">
            {{ slide.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="slider-bottom">
    <div class="container mx-auto top-overlay bg-white">
      <hooper class="" ref="carousel" @slide="updateCarousel" :wheelControl="false">
        <slide v-for='slide in trimSlides' :key="slide.title">
          <div class="w-full flex flex-wrap ">
            <div class="w-full md:w-1/2 h-full">
              <div class="bg-grey-lighter md:h-full w-full md:flex-1 bg-cover bg-center bg-no-repeat img-height" :style="'background-image:url('+slide.image+')'">
              </div>
            </div>
            <div class="w-full md:w-1/2 p-12 flex flex-col justify-center" v-html="slide.description">
            </div>
          </div>
        </slide>
        <navigation class="absolute top-0 bottom-0 w-full md:w-1/2" slot="hooper-addons"></navigation>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
  </div>
</div>
</template>

<script>
import {
  Hooper
} from 'hooper';

const getTop = function(element, start) {
  // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
  if (element.nodeName === 'HTML') return -start;
  return element.getBoundingClientRect().top + start;
};

function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

export default {
  props: ['heading', 'slides'],
  data() {
    return {
      currentSlide: 0,
      trimSlides: this.slides.map(({
        title,
        image,
        description
      }) => {
        console.log(description.slice(1, -2));
        return {
          title,
          image,
          description: description.slice(1, -2)
        }
      })
    }
  },
  components: {
    Hooper
  },
  watch: {
    currentSlide() {
      this.$refs.carousel.slideTo(this.currentSlide)
    }
  },
  created() {
    console.log(this.slides);
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideTo(ev, slide) {
      this.$refs.carousel.slideTo(slide);
      return this.smoothScroll(ev)
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.currentSlide = payload.currentSlide;
    },
    smoothScroll(ev) {
      console.log("Scroll now");
      const duration = 500;
      const offset = 0;
      const container = window;
      ev.preventDefault();
      const scrollTo = this.$refs.outer;
      if (!scrollTo) return; // Do not scroll to non-existing node

      const startPoint = window.scrollTop || window.pageYOffset;
      // Get the top position of an element in the document
      // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
      let end = getTop(scrollTo, startPoint);

      // Ajusts offset from the end
      end += offset;

      const clock = Date.now();
      const step = function() {
        // the time elapsed from the beginning of the scroll
        const elapsed = Date.now() - clock;
        // calculate the scroll position we should be in
        let position = end;
        if (elapsed < duration) {
          position = startPoint + (end - startPoint) * easeInOutCubic(elapsed / duration);

          requestAnimationFrame(step);
        }

        container === window ? container.scrollTo(0, position) : (container.scrollTop = position);
      };
      step();
    },
  }
}
</script>

<style>
</style>
