<template>
<div class="flex flex-wrap">
    <div class="w-full md:w-1/2 md:pr-8">
        <h6 class="font-bold mt-4 mb-2">Account Details</h6>
        <div v-for="field in personalFields">
            <input v-if="['text', 'password', 'file', 'email'].includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2 border rounded py-6 w-full pl-4 h-16 text-sm" :class="{'border-red-500':fieldErrors[field.name],'border-gray-300 mr-2': !fieldErrors[field.handle], required:field.required}"/>
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option in field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
        </div>
    </div>
    <div class="w-full md:w-1/2 md:pl-8">
        <h6 class="font-bold mt-4 mb-2">Address</h6>
        <div v-for="field in addressFields">
            <input v-if="['text', 'password', 'file', 'email'].includes(field.type)" :autocomplete="field.type === 'password' ? 'new-password' : 'off'" :type="field.type" v-model="field.value" :name="field.name" :placeholder="t(field)"
                class="my-2  border rounded py-6 w-full pl-4 h-16 text-sm"  :class="{'border-red-500':fieldErrors[field.name],'border-gray-300 mr-2': !fieldErrors[field.handle], required:field.required}"/>
            <select class="my-2 border-b py-6 w-full pl-4 h-16 text-sm input" v-on:change="ev => field.name == 'fields[country]' && changeCountry(ev)" v-if="field.type === 'select'" v-model="field.value" :name="field.name" :placeholder="t(field)">
                <option v-for="option in field.options" :value="option">{{ field.mapping[option] }}</option>
            </select>
        </div>
        <h6 v-if="showGiftaid" class="font-bold mt-4 mb-2">Gift Aid</h6>
        <div v-for="field in giftaidFields" :key="field.name">
            <gift-aid  v-if="showGiftaid" :content="giftaid" account :user="{}" v-model="field.value"/>
        </div>

        <div class="flex-1 w-full mt-2">
            <span v-on:click="submit" class="flex justify-center items-center cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-4">
                <svg v-if="submitting" id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24.967 24.967" class="mr-4">
                    <g data-name="Group 554" transform="translate(-129.283 0)">
                        <path id="Path_14" data-name="Path 14"
                            d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
                            transform="translate(349.182 98.262)" fill="#fff" />
                        <path id="Path_15" data-name="Path 15"
                            d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
                            transform="translate(326.478 121.162)" fill="#fff" />
                        <path id="Path_16" data-name="Path 16"
                            d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
                            transform="translate(349.573 131.234)" fill="#fff" />
                        <path id="Path_17" data-name="Path 17"
                            d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
                            transform="translate(359.45 121.162)" fill="#fff" />
                    </g>
                </svg>
                {{submitting ? "Processing" : "Submit"}}
            </span>
        </div>
        <div v-if="showErrors && fieldErrorList.length" class="text-sm text-red-500 mt-2 leading-none flex flex-col">
            <span class="text-xs uppercase font-medium" v-for="field, index in fieldErrorList">{{ fieldErrors[field] }}<span v-if="index < fieldErrorList.length -1">, </span></span>
        </div>
    </div>
</div>
</template>

<script>
import EventBus from '../EventBus';
import {countries, countryMap} from '../utils/countries'

const fieldMap = [{
        name: "userId",
        type: "hidden",
    },
    {
        name: "firstName",
        text: "firstName",
        required: true,
        title: "First Name",
        default: '',
        type: "text",
    },
    {
        name: "lastName",
        text: "lastName",
        required: true,
        title: "Last Name",
        default: '',
        type: "text",
    },
    {
        name: "newPassword",
        text: "newPassword",
        title: "New Password",
        default: '',
        type: "password",
    },
    {
        name: "password",
        title: "Current Password",
        text: "currentPassword",
        default: '',
        valid: (val, changed) => changed.includes("newPassword") || changed.includes("email") ? (val ? '' : 'Current password required to change email or password') : '',
        type: "password",
    },
    {
        name: "email",
        text: "email",
        required: true,
        title: "Email",
        default: '',
        type: "email",
    },
    {
        name: "fields[phone]",
        text: "phone",
        required: true,
        title: "Phone",
        default: '',
        type: "text",
    },
    {
        name: "fields[contactType]",
        title: "Account Type",
        text: "contactType",
        type: "select",
        default: "Household",
        options: [
            "Household",
            "Business"
        ],
        mapping: {
            Household: "Personal",
            Business: "Business",
        },
    },
    {
        name: "fields[street]",
        text: "street",
        default: '',
        title: "Street",
        required: true,
        type: "text",
    },
    {
        name: "fields[suburb]",
        text: "suburb",
        default: '',
        required: true,
        title: "Suburb",
        required: true,
        type: "text",
    },
    {
        name: "fields[state]",
        text: "state",
        default: '',
        title: "State/Province",
        type: window.userform && window.userform.visibility && window.userform.visibility.state ? "select" : "hidden",
        options: [],
        required: window.userform && window.userform.visibility && window.userform.visibility.state,
        mapping: {},
    },
    {
        name: "fields[country]",
        text: "country",
        default: window.countryCode,
        title: "Country",
        type: "select",
        required: true,
        options: countries,
        mapping: countryMap,
    },
    {
        name: "fields[postCode]",
        text: "postCode",
        default: '',
        required: true,
        title: "Postcode",
        type: "text",
    },
    {
        name: 'fields[giftAid]',
        default: false,
        type: 'giftaid',
    }
]

console.log(fieldMap)

export default {
    props: {
        values: Object,
        showGiftaid: Boolean,
        giftaid: Object,
        siteUrl: String,
        text: Object,
    },
    data() {
        return {
            fields: fieldMap.reduce((prev, field) => ({
                ...prev,
                [field.name]: {
                    ...field,
                    value: field.name == 'fields[country]' ?
                        this.values[field.name] && this.values[field.name].length == 2 ? this.values[field.name] : window.countryCode :
                    this.values[field.name] || field.default,
                }
            }), {}),
            showErrors: false,
            personal: [
                "fields[contactType]",
                "firstName",
                "lastName",
                "email",
                "fields[phone]",
                "newPassword",
                "password",
            ],
            address: [
                "fields[street]",
                "fields[suburb]",
                "fields[state]",
                "fields[postCode]",
                "fields[country]",
            ],
            submitting: false,
            keepState: true,
        }
    },
    created() {
        console.log(this.values)
    },
    mounted() {
        EventBus.$emit('craft:fetch-state', {
            code: this.fields['fields[country]'].value && this.fields['fields[country]'].value.length == 2 ? this.fields['fields[country]'].value : window.countryCode,
            callback: this.updateStates,
        })
    },
    computed: {
        personalFields() {
            return this.personal.map((name) => this.fields[name])
        },
        addressFields() {
            return this.address.map((name) => this.fields[name])
        },
        giftaidFields() {
            return [this.fields['fields[giftAid]']];
        },
        changedFields() {
            let changed = ['userId']
            fieldMap.forEach((field) => {
                //console.log(field.name, this.values, this.values[field.name])
                if (this.values[field.name]) {
                    if (this.fields[field.name] && this.values[field.name] !== this.fields[field.name].value)
                        changed.push(field.name)
                } else if (this.fields[field.name].value) {
                    changed.push(field.name)
                }
            })

            return changed;
        },
        fieldErrors() {
            let errors = {}

            if (!this.showErrors)
                return errors;

                fieldMap.forEach((field) => {
                    console.log(field.required, this.values[field.name]);
                    if (field.required && !this.fields[field.name].value)
                        errors[field.name] = `${field.title} cannot be empty`;
                })

            errors.password = this.fields.password.valid(this.fields.password.value, this.changedFields)

            return errors;
        },
        fieldErrorList() {
            return fieldMap.filter(({name}) => this.fieldErrors[name]).map(field => field.name);
        }
    },
    methods: {
        submit() {
            this.showErrors = true;
            if (this.submitting) {
                return;
            }
            this.submitting = true;
            if (this.fieldErrors.password.length) {
                console.log("Error with password")
                this.submitting = false;
                return;
            }
            console.log("Submitting changes", this.changedFields);
            let data = this.changedFields.reduce((prev, name) => name ? {
                ...prev,
                [name]: this.fields[name].value,
            } : prev, {})
            if (data.email)
                data.username = data.email

            EventBus.$emit('craft:post', {
                callback: this.postResponse,
                override: this.siteUrl,
                action: 'users/save-user',
                data,
            })
        },
        changeCountry(ev) {
            this.keepState = false;
            EventBus.$emit('craft:fetch-state', {code: ev.target.value, callback: this.updateStates})
        },
        updateStates(states) {
            const options = states.map(({value}) => value);
            const mapping = {}
            states.forEach(({title, value}) => {
                mapping[value] = title
            })
            this.fields['fields[state]'] = {
                ...this.fields['fields[state]'],
                options,
                mapping,
                value: this.values['fields[state]'] && this.keepState && options.includes(this.values['fields[state]']) ? this.values['fields[state]'] : options[0],
            }
        },
        postResponse(resp) {
            console.log(resp)
            this.submitting = false;
        },
        t(field) {
            return this.text[field.text] || field.title
        }
    }
}
</script>
