<template>
  <div class="box mt-6">
    <input ref="input" v-on:change="changeName" class="inputfile inputfile-2 absolute opacity-0 overflow-hidden h-1 w-1" type="file" :name="name" id="file-2" data-multiple-caption="{count} files selected">
    <label for="file-2">
      <svg xmlns="http://www.w3.org/2000/svg" class="inline" width="20" height="17" viewbox="0 0 20 17"><path
        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg>
      <span>{{ title }}</span></label>
  </div>
</template>

<script>
export default {
    props: ["name"],
    data() {
      return {
        title: "Upload a file"
      }
    },
    methods: {
      changeName(ev) {
        let files = this.$refs.input.files;
        let fileName;
        if (files && files.length > 1)
          fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        else
          fileName = ev.target.value.split( '\\' ).pop();

        this.title = fileName || "Choose a file&hellip;"
      }
    }
}
</script>
