<template>
<div class="">
  <div class="flex flex-wrap justify-between">
    <div class="card mb-3 w-full mb-2 md:w-1/2 md:pr-4" v-for="(section, index) in sections">
      <input type="hidden" :name="matrixGroup + '[new' + (index + 1) + ']' + '[type]'" value="contributor">
      <input type="hidden" :name="matrixGroup + '[new' + (index + 1) + ']' + '[enabled]'" value="1">
      <hr>
      <div class="card-body border w-full flex justify-center items-center h-24 p-6">
        <div class="w-full" v-for="(addition, index) in section.additionals">
          <input type="text" class="form-control mb-2 w-full" placeholder="Item" v-model="addition.item">
        </div>
        <div class="flex items-end w-full">
          <croppa :disable-drag-and-drop="true" :disable-drag-to-move="true" :disable-scroll-to-zoom="true" :disable-rotation="true" :canvas-color="'#ebf8ff'" :placeholder-font-size="16" placeholder="Upload" :input-attrs="{name: matrixGroup + '[new' + (index + 1) + ']' + imageField}"
            :width="70" :height="70" :show-remove-button="true" :remove-button-color="'blue'" :remove-button-size="20" class="profile-upload" v-model="myCroppa"></croppa>
          <br>
          <div class="w-full ml-4">
            <input placeholder="Full name" type="text" :name="matrixGroup + '[new' + (index + 1) + ']' + matrixSingle" class="border-b py-6 w-full pl-4 h-16 text-sm input">
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 w-full md:w-1/2 flex items-end md:pr-4">
      <div class="point border hover:bg-blue-100 w-full flex justify-center items-center h-24" @click="addNewSection">
        <span class="uppercase text-sm font-bold tracking-wide btn-success">
          Add Contributor <span>+</span>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<style>
.profile-upload canvas {
  overflow: hidden;
  border-radius: 9999px;
  border: solid 1px #e2e8f0;
}

.card:nth-child(even) {
  padding-right: 0px !important;
}

.croppa-container {
  background-color: transparent !important;
}
</style>

<script type="text/javascript">
import EventBus from '../EventBus'

export default {
  props: {
    matrixGroup: String,
    matrixSingle: String,
    imageField: String,
  },
  data() {
    return {
      sections: [{
        item: '',
        additionals: []
      }],
    }
  },
  methods: {
    addNewSection() {
      this.sections.push({
        item: '',
        additionals: []
      })
    },
  },
}
</script>
