<template>
<div>
    <div v-if="!simple" class="w-full flex flex-col align-stretch">
      <div v-if="!expired" class="flex justify-between border-t border-b mb-8">
        <span class="w-1/3 pl-4 py-4 flex flex-col justify-center">
          <h4 class="text-gray-800 m-0 font-normal">{{ remain }} <span class="text-sm block text-gray-600 font-normal">days left</span></h4>
        </span>
        <span class="w-1/3 border-r border-l pl-4  flex flex-col justify-center">
          <h4 class="text-gray-800 m-0 py-4 font-normal">${{ pretty }} <span class="text-sm block text-gray-600 font-normal">given</span></h4>
        </span>
        <span class="w-1/3 pl-4  flex flex-col justify-center">
          <h4 class="text-gray-800 m-0 py-4 font-normal">{{ percent }}% <span class="text-sm block text-gray-600 font-normal">reached</span></h4>
        </span>
      </div>
      <div v-if="expired" class="flex justify-between border-t border-b mb-8">
        <span class="w-1/2 border-r pl-4  flex flex-col justify-center">
          <h4 class="text-gray-800 m-0 py-4 font-normal">${{ pretty }} <span class="text-sm block text-gray-600 font-normal">given</span></h4>
        </span>
        <span class="w-1/2 pl-4  flex flex-col justify-center">
          <h4 class="text-gray-800 m-0 py-4 font-normal">{{ percent }}% <span class="text-sm block text-gray-600 font-normal">reached</span></h4>
        </span>
      </div>
      <div class="relative mx-4">
        <div class="rounded-lg bg-blue-100 h-2 track"></div>
        <div class="rounded-lg bg-blue-600 h-2 absolute top-0 left-0" :style="{width: percent + '%'}"></div>
      </div>
      <div class="flex  mt-2 justify-between mx-4">
        <span>
          <h4 class="text-gray-800 font-normal">${{ pretty }} <span class="text-sm text-gray-600 font-normal">given</span></h4>
        </span>
        <span>
          <h4 class="font-extrabold text-blue-600">${{ target }} <span class="text-sm text-gray-600 font-normal">goal</span></h4>
        </span>
      </div>

    </div>
    <span v-if="simple">${{ pretty }}</span>
</div>
</template>

<script>
import EventBus from '../EventBus';

export default {
  props: {
    fundraiserId: String,
    target: Number,
    remain: Number,
    expired: Boolean,
    contributor: String,
    simple: Boolean
  },
  data() {
    return {
      loaded: false,
      tracked: 0,
    }
  },
  beforeMount() {
    EventBus.$on('craft:fundraising-tracking', this.tracking);
  },
  computed: {
    amount() {
        if (!this.loaded || !this.tracked) {
            return 0;
        } else if (this.contributor) {
            return this.tracked.contributors ? this.tracked.contributors[this.contributor] || 0 : 0
        } else {
            return this.tracked.total
        }
    },
    percent() {
      if (this.loaded) {
        return (Math.min(this.amount / 100.0, this.target) / this.target * 100).toFixed(0);
      }
      return 0;
    },
    pretty() {
      if (this.loaded) {
        return (this.amount / 100.0).toFixed(0);
      }
      return 0;
    },
  },
  methods: {
    tracking(data) {
      console.log(data, this.fundraiserId);
      this.loaded = true;
      if (data[this.fundraiserId]) {
        this.tracked = data[this.fundraiserId]
      } else {
        this.tracked = data[0]
      }
    }
  }
}
</script>
