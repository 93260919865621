<template>
<div class="w-full">
  <div class="w-full">
    <h6 class="font-normal mb-2">
      <label for="fields-projectTarget">{{ title }}</label>
    </h6>
    <div class="flex">
      <div v-for="(option, index) in options" v-on:click="setOption(index)" class="w-full flex" :class="{'pr-4': index === 0}">
        <div class="border text-center w-full  px-8 input-button py-4" :class="{active: current == index}">{{ option }}</div>
      </div>
    </div>
  </div>
  <slot>
  </slot>
  <slot name=" before">
  </slot>
  <slot name="switchable" v-if="slotIfSelected === current">
  </slot>
  <slot name="altswitchable" v-if="slotIfSelected !== current">
  </slot>
  <slot name="after">
  </slot>
</div>
</template>

<script>
export default {
  props: {
    title: String,
    options: Array,
    slotIfSelected: Number
  },
  data() {
    return {
      current: 0,
    }
  },
  methods: {
    setOption(index) {
      this.current = index
    }
  }
}
</script>
