import Vue from "vue";
//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import VBodyScrollLock from 'v-body-scroll-lock'
import VueObserveVisibility from 'vue-observe-visibility'
import Components from "./components";
import Plugins from './plugins';
import "./css/main.scss";
import dayjs from 'dayjs';

import EventBus from './EventBus'
import VideoBg from 'vue-videobg'

Vue.component('video-bg', VideoBg);

Vue.config.productionTip = true;


Vue.use(VueObserveVisibility)
Vue.use(VBodyScrollLock)

function loadEmbeds(data) {
    let {embed, target} = data;
    idonate.Embed(embed, target);
}

new Vue({
  el: "#app",
  delimiters: ["[[", "]]"],
  components: {VideoBg},
  data() {
      return {
          csrfName: window.csrfTokenName,
          csrfToken: window.csrfTokenValue,
          otherSitesInGroup: window.otherSitesInGroup,
          donationText: window.donationText,
          donateForms: window.donateForms,
          userform: window.userform,
          cookie: window.cookieSettings,
          showOtherSites: false,
          mainScroll: false,
          coverDimensions: [400, 200],
          myCroppa: {},
          tracking: {},
          states: {},
          fundraiserProfileImageError: '',
          fundraiserCoverImageError: '',
          manualPaymentError: false,
          manualPaymentSuccess: false,
          user: window.currentUser,
          navigating: false,
          giftaid: window.giftaid,
          showGiftaid: window.showGiftaid,
          showConsent: window.showConsent,
          consent: window.consent,
          buttons: [{
              id: 1,
              name: 'Button 1',
              method: this.buttonOne
            },
            {
              id: 2,
              name: 'Button 2',
              method: this.buttonTwo
            },
          ],
      }
  },
  mounted: function() {
      window.addEventListener("resize", this.onResize);
      this.loadCookies()
      EventBus.$on('craft:post', this.craftPostHandler)
      EventBus.$on('craft:tab-change', this.onResize)
      EventBus.$on('craft:fetch-state', this.fetchState);
      EventBus.$on('craft:load-embeds', loadEmbeds);
      fetch(`${window.trackerUrl}/api/v2/fundraising`)
      .then(r => r.json())
      .then(data => {
          EventBus.$emit('craft:fundraising-tracking', data);
          this.tracking = data
      })
      this.fetchState({code: 'au'})
      //EventBus.$on('craft:noscroll', this.stopMainScroll)
      //EventBus.$on('craft:scroll', this.allowMainScroll)

      // this.body = document.getElementsByTagName('body')[0]
      // this.html = document.getElementsByTagName('html')[0]
      // this.element = document.querySelector('.popup-inner');
  },
  destroyed: function() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    gaPreference() {
        return this.cookie && this.cookie.preference && this.cookie.preference.gtag && this.cookie.preference.gtag.analytics_storage == 'granted'
    }
  },
  methods: {
      failManualPayment() {
          this.manualPaymentError = true;
      },
      succeedManualPayment() {
          this.manualPaymentSuccess = true;
      },
      navigate() {
        this.navigating = true;
      },
      reload() {
          window.location.reload();
      },
      fetchState({code, callback}) {
          if (!code)
            return;
          if (this.states[code]) {
              if (callback) {
                  callback(this.states[code]);
              }
          } else {
              console.log(`${window.trackerUrl}/api/v2/states/${code}.json`)
              fetch(`${window.trackerUrl}/api/v2/states/${code}.json`)
              .then(r => r.json())
              .then(json => {
                  this.states[code] = json
                  if (callback) {
                      callback(this.states[code]);
                  }
              })
          }
      },
      craftPostHandler(req) {
          console.log("Submitting form")
          if (!req.action) {
              throw "Craft POST must contain a valid action";
          }
          let form = this.buildForm(req.data, req.action);
          fetch(req.override || '/', {
              method: 'POST',
              body: form,
              /*JSON.stringify({
                  ...req.data,
                  action: req.action,
                  [this.csrfName]: this.csrfToken
              }),*/
              headers: req.headers || {
                  'Accept': '*/*'
              }
          })
          .then(resp => {
              if (req.callback) {
                  req.callback(resp)
              }
          });
      },
      setFundraiserCoverImageError(err) {
        this.fundraiserCoverImageError = err;
      },
      setFundraiserProfileImageError(err) {
        this.fundraiserProfileImageError = err;
      },
      nDaysLater(n) {
          return dayjs().add(n, 'days').format('YYYY-MM-DD');
      },
      maxDate(d1, d2) {
          return dayjs(d1).isAfter(dayjs(d2)) ? d1 : d2;
      },
      buildForm(data, action) {
          let form = new FormData();
          Object.keys(data).forEach((key) => {
              form.set(key, data[key]);
          })
          form.set(this.csrfName, this.csrfToken);
          form.set('action', action)
          return form
      },
      buttonOne() {
        console.log('buttonOne');
      },
      buttonTwo() {
        console.log('buttonTwo');
      },
      onResize(ev) {
        const elem = this.$refs.coverimage;
        if (elem) {
          console.log(elem.clientWidth, elem.clientHeight);
          this.coverDimensions = [elem.clientWidth, elem.clientWidth/2]
        }
      },
      uploadCroppedImage() {
      this.myCroppa.generateBlob(
            blob => {
              // write code to upload the cropped image file (a file is a blob)
            },
            'image/jpeg',
            0.8
          ); // 80% compressed jpeg file
      },
      allowAllCookies() {
        if (!window.excludeFromAnalytics) {
            this.saveCookies({
                gtag: {
                    analytics_storage: 'granted',
                }
            })
        }
      },
      changeGA(event) {
          console.log("changeGA", event)
          if (event.target.checked && !window.excludeFromAnalytics) {
              this.saveCookies({
                  gtag: {
                      analytics_storage: 'granted'
                  }
              })
          } else {
              this.saveCookies({
                  gtag: {
                      analytics_storage: 'denied'
                  }
              })
          }
      },
      saveCookies(cookies) {
          console.log("Dismiss from main")
          this.cookie.resolved = true

          try {
              localStorage.setItem('cookie-preferences', JSON.stringify({
                  ...this.cookie.preference,
                  ...cookies,
                  date: Object.keys(this.user).length ? dayjs().startOf('day').add(1, 'year').unix() : dayjs().startOf('day').add(1, 'day').unix(),
              }))
          } catch (error) {
              console.log(error)
          }

          this.cookie = {
              ...this.cookie,
              preference: {
                  ...this.cookie.preference,
                  cookies,
              }
          }

          this.refreshCookieSettings()
      },
      loadCookies() {
          if (window.excludeFromAnalytics) {
              window.gtag('consent', 'update', {
                  'analytics_storage': 'denied'
                });
          } else if (!this.cookie || !this.cookie.enable) {
              window.gtag('consent', 'update', {
                  'analytics_storage': 'granted'
              })
              return;
          }


          let data = undefined;
          try {
              data = JSON.parse(localStorage.getItem('cookie-preferences'))
          } catch (error) {
              console.log(error)
          }
          if (data && dayjs().isBefore(dayjs.unix(data.date))) {
              this.cookie = {
                  ...this.cookie,
                  resolved: true,
                  preference: data,
              }
              this.refreshCookieSettings()
          }
      },
      refreshCookieSettings() {
          if (window.excludeFromAnalytics) {
              window.gtag('consent', 'update', {
                  'analytics_storage': 'denied'
                });
          } else if (!this.cookie) {
              //enable google analytics
              window.gtag('consent', 'update', {
                  'analytics_storage': 'granted'
                });
          } else if (this.cookie.preference && this.cookie.preference.gtag) {
              window.gtag('consent', 'update', {
                  'analytics_storage': this.cookie.preference.gtag.analytics_storage || 'denied'
              })
          }
      }
      /*
      closeModal() {
            this.$set(this, 'modalIsOpen', false)
        },
        openModal() {
            this.$set(this, 'modalIsOpen', true)
        },*/
      // stopMainScroll(element) {
      //     disableBodyScroll(this.element);
      // },
      // allowMainScroll(element) {
      //     enableBodyScroll(this.element);
      // },
  },
  // beforeDestroy() {
  //     clearAllBodyScrollLocks();
  // }
});


window.onload=function(){
    var elem = document.getElementById("loader");
    if (elem)
        elem.parentNode.removeChild(elem);
}

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
