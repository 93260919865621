import Vue from 'vue';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import vueTopprogress from 'vue-top-progress';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import 'swiper/dist/css/swiper.css'
//import DankCOmponents from 'dank-plugin';

Vue.component('Hooper', Hooper);
Vue.component('Slide', Slide);
Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.component('HooperPagination', HooperPagination);
Vue.component('Navigation', HooperNavigation);
Vue.use(vueTopprogress);
Vue.use(Croppa);




//Vue.component('DankPlugin', DankComponents);

var body = document.getElementsByTagName("body")[0];

function classToggle() {
    body.classList.toggle('noscroll');
}

//document.querySelector('.popup').addEventListener('click', classToggle);
