<template>
<div>
    <div ref="formdata">
        <slot></slot>
    </div>

    <!--<h4 v-if="!success && !processing" class="text-center font-bold mb-2">Donate to {{ title }}</h4>
        <h4 v-if="success" class="text-center mb-2">Thanks for your donation!</h4>
        <h3 v-if="!success && processing" class="text-center mb-2">Processing...</h3>-->
    <div v-if="!success" v-for="field in mappedFields" :class="'flex flex-col'">
        <input v-model="field.value" v-if="!['submit'].includes(field.type)" :class="'self-stretch w-full h-16 py-6 pl-4 text-sm input mb-2 ' + (fieldErrors[field.handle] ? 'border-b border-red-500' : 'border-b border-gray-200')" :type="field.type"
            :id="'form-input-' + field.handle" :placeholder="field.label" />
    </div>
    <div v-if="!success" class="mb-2 self-stretch flex">
        <span v-on:click="submit" class="flex justify-center items-center cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-4">
            <svg v-if="submitting" id="loader-group" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24.967 24.967" class="mr-4">
                <g data-name="Group 554" transform="translate(-129.283 0)">
                    <path id="Path_14" data-name="Path 14"
                        d="M-215.448-76.222c-.092-.08.462-1.9,1.339-1.175,1.138.947,5.69,1.239,4.684-1.47-1.27-3.42.3-5.133,2.114-5.133h0c1.812,0,3.383,1.713,2.114,5.133-1.005,2.708,3.546,2.416,4.685,1.47.877-.729,1.391.951,1.3,1.031a12.435,12.435,0,0,1-8.2,3.071,12.433,12.433,0,0,1-7.941-2.851l-.091-.076"
                        transform="translate(349.182 98.262)" fill="#fff" />
                    <path id="Path_15" data-name="Path 15"
                        d="M-175.155-100.562c-.08.092-1.9-.462-1.175-1.339.946-1.138,1.238-5.69-1.47-4.684-3.42,1.27-5.133-.3-5.133-2.114h0c0-1.812,1.713-3.383,5.133-2.114,2.708,1.005,2.416-3.546,1.47-4.685-.73-.877.95-1.391,1.031-1.3a12.435,12.435,0,0,1,3.071,8.2,12.433,12.433,0,0,1-2.851,7.941l-.076.091"
                        transform="translate(326.478 121.162)" fill="#fff" />
                    <path id="Path_16" data-name="Path 16"
                        d="M-199.774-128.307c.092.08-.462,1.9-1.339,1.175-1.138-.947-5.69-1.238-4.684,1.47,1.27,3.42-.3,5.133-2.114,5.133h0c-1.812,0-3.383-1.713-2.114-5.133,1.005-2.708-3.546-2.417-4.685-1.47-.877.729-1.39-.95-1.3-1.031a12.435,12.435,0,0,1,8.2-3.071,12.433,12.433,0,0,1,7.941,2.851l.091.076"
                        transform="translate(349.573 131.234)" fill="#fff" />
                    <path id="Path_17" data-name="Path 17"
                        d="M-227.24-116.8c.08-.092,1.9.462,1.175,1.339-.947,1.138-1.239,5.69,1.47,4.684,3.42-1.27,5.133.3,5.133,2.114h0c0,1.812-1.713,3.383-5.133,2.114-2.708-1.005-2.416,3.546-1.47,4.685.729.877-.951,1.39-1.031,1.3a12.434,12.434,0,0,1-3.071-8.2,12.433,12.433,0,0,1,2.851-7.941l.076-.091"
                        transform="translate(359.45 121.162)" fill="#fff" />
                </g>
            </svg>
            {{submitting ? "Processing" : (submitText || "Submit")}}
        </span>
    </div>
    <div v-if="success">
        All registered
    </div>
    <!--<div v-if="success" class="mx-2 mb-2 self-stretch flex">
            <span v-on:click="hideDonation" class="cursor-pointer flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 py-1">Close</span>
        </div>-->
</div>
</template>

<script>
import Vue from 'vue';
import EventBus from '../EventBus'

export default {
    props: {
        title: String,
        project: String,
        fields: Array,
        handle: String,
        hiddenFields: Array,
        submitText: String,
    },
    data() {
        return {
            status: "initial",
            showErrors: false,
            submitting: false,
            extraFields: {},
            /*subscribe: false,
            processing: false,
            success: false,
            amount: 50,*/
            form: {},
            mapping: this.fields.map((field) => field.handle),
        }
    },
    mounted() {
        if (this.hiddenFields) {
            console.log("Processing hidden fields from args")
            this.hiddenFields.forEach(({
                name,
                value
            }) => {
                this.extraFields[name] = value;
            })
        } else if (this.$refs.formdata) {
            let specialInputs = this.$refs.formdata.getElementsByTagName('input')
            console.log("Currently ", specialInputs.length, "inputs")
            for (var i = 0; i < specialInputs.length; i++) {
                var elem = specialInputs.item(i)

                if (elem.getAttribute("value")) {
                    console.log(elem.getAttribute("name"), elem.getAttribute("value"))
                    this.extraFields[elem.getAttribute("name")] = elem.getAttribute("value")
                }
            }
        }

        this.form = this.fields.reduce((prev, field) => {
            if (!field || !field.handle)
                return prev;
            console.log(field.handle)
            field['value'] = field.type === 'number' ? 0 : '';
            if (!this.extraFields[field.name])
                prev[field.handle] = field;
            return prev;
        }, {})
    },
    computed: {
        /*donateButtonCls() {
        if (this.footer) {
          return "cursor-pointer rounded-sm flex-1 bg-white uppercase text-sm brand-text border-2 border-white text-white text-center px-2 h-12 flex justify-center items-center";
        } else {
          return "cursor-pointer rounded-sm flex-1 bg-blue-600 uppercase border text-sm border-blue-600 border-2 text-white text-center px-2 h-12 flex justify-center items-center";
        }
    },*/
        mappedFields() {
            return Object.keys(this.form).length ? this.mapping.filter((handle) => handle && !this.extraFields[handle]).map((handle) => this.form[handle]) : [];
        },
        fieldErrors() {
            let out = {};
            if (!this.showErrors) {
                return out;
            }

            for (let field of this.mappedFields) {
                if (field.handle && field.required && !field.value) {
                    out[field.handle] = true;
                }
            }

            return out;
        },
    },
    methods: {
        /*
        toggleSubscribe() {
            this.subscribe = !this.subscribe;
        },
        */
        reset() {
            this.processing = false;
            this.success = false;
        },
        submit() {
            this.showErrors = true;
            if (this.processing)
                return;
            this.processing = true;

            if (Object.keys(this.fieldErrors).length) {
                console.log(this.fieldErrors);
                this.processing = false;
                return;
            }
            let data = {};
            Object.keys(this.form).forEach((key) => {
                if (key && key !== 'undefined') {
                    data[key] = this.form[key].value
                }
            })

            Object.keys(this.extraFields).forEach((key) => {
                if (key !== 'undefined')
                    data[key] = this.extraFields[key]
            })

            console.log("About to submit for trip registration")
            this.submitting = true;
            EventBus.$emit('craft:post', {
                callback: this.craftResponse,
                action: this.extraFields['action'] || '/',
                data,
            });
        },
        craftResponse(resp) {
            this.submitting = false
            if (resp.ok) {
                this.success = true;
                console.log(resp)
                this.$emit('success', {
                    resp,
                })
            } else {
                this.$emit('failed', {
                    resp,
                })
            }
            this.processing = false;

        }
    }
};
</script>
