<template>
    <div>
        <popup-modal v-if="success" :show="success" v-on:close="reload">
          <div class="max-w-lg w-screen px-6 py-6 bg-white confirmation">
            <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
              <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="124.878" height="118.319" viewBox="0 0 124.878 118.319">
                <g id="Group_632" data-name="Group 632" transform="translate(2698.52 -2981.882)">
                  <path id="Path_303" data-name="Path 303" d="M112.22,71.349s4.925-19.859,6.386-27.029,7.76-7.517,9.844-6.071,4.63,38.489,4.63,43.239,1.25,10.927-5.393,17.684-16.271,16.256-18.923,18.865-6.928,5.811-6.858,11.161,0,10.078,0,10.078H74.227l2.13-32.111,25.55-15.987Z"
                    transform="translate(-2706.814 2960.926)" fill="#3f9de5" />
                  <path id="Path_302" data-name="Path 302" d="M98.861,102.515s-7,8.249-23.513,8.249c.112.04.838-7.583.838-7.583s.563-20.066,22.675-19.3c.235,0,13.574-16.97,13.574-16.97s11.185,6.919,2.868,16.97S98.861,102.515,98.861,102.515Z" transform="translate(-2706.66 2964.939)"
                    fill="#2f83c3" />
                  <path id="Path_304" data-name="Path 304" d="M95.18,71.349S90.255,51.49,88.794,44.32s-7.76-7.517-9.844-6.071-4.63,38.489-4.63,43.239-1.25,10.927,5.393,17.684,16.271,16.256,18.923,18.865,6.928,5.811,6.858,11.161,0,10.078,0,10.078h27.679l-2.13-32.111-25.55-15.987Z"
                    transform="translate(-2772.746 2960.926)" fill="#3f9de5" />
                  <path id="Path_305" data-name="Path 305" d="M94.866,102.515s7,8.249,23.513,8.249c-.112.04-.838-7.583-.838-7.583s-.563-20.066-22.675-19.3c-.235,0-13.574-16.97-13.574-16.97s-11.185,6.919-2.868,16.97S94.866,102.515,94.866,102.515Z"
                    transform="translate(-2759.227 2964.939)" fill="#2f83c3" />
                  <ellipse id="Ellipse_304" data-name="Ellipse 304" cx="30.693" cy="30.693" rx="30.693" ry="30.693" transform="translate(-2666.682 2981.882)" fill="#f5f5f5" />
                  <path id="Path_306" data-name="Path 306" d="M-2081.611,2738.923l10.308,10.308,17.706-17.706" transform="translate(-568 272)" fill="none" stroke="#2e5492" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                </g>
              </svg>
              <h3>Donation Recieved</h3>
              <p>Your manual payment has been successfully processed.</p>
            </div>

          </div>

        </popup-modal>
        <popup-modal v-if="error" :show="error" v-on:close="reload">
          <div class="flex flex-col items-cewnter justify-center max-w-lg w-screen px-6 py-6  bg-white">
            <div class="flex flex-col justify-center items-center mt-2 mb-12 normal-case tracking-normal ">
              <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="119.455" height="119.455" viewBox="0 0 119.455 119.455">
                <g id="Group_634" data-name="Group 634" transform="translate(2880.717 -2986.847)">
                  <circle id="Ellipse_305" data-name="Ellipse 305" cx="59.728" cy="59.728" r="59.728" transform="translate(-2880.717 2986.847)" fill="#f5f5f5" />
                  <g id="Group_633" data-name="Group 633" transform="translate(9.682 8.682)">
                    <line id="Line_191" data-name="Line 191" x2="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                    <line id="Line_192" data-name="Line 192" x1="30.636" y2="30.636" transform="translate(-2845.5 3022.5)" fill="none" stroke="#c44949" stroke-linecap="round" stroke-width="4" />
                  </g>
                </g>
              </svg>
              <h3>Unfortunately your donation could not be processed.</h3>
            </div>
          </div>
        </popup-modal>
    </div>
</template>

<script>
export default {
    props: {
        error: Boolean,
        success: Boolean,
        reload: Function,
    },
}
</script>
