<template>
    <div class="header__item relative" >
        <span class="logo m-0 flex justify-center items-center">
          <a class="h-12" :href="url">
              <img class="h-12" :class="{'md:border-none lg:border-solid country-colour pr-4 border-r h-12': !noName}" :src="logo" :alt="alt" style="max-height:60px; object-fit: contain;"/>
          </a>
          <div class="flex flex-col mb-0 md:ml-4 mr-4 mt-2 relative"  :class="{'switcher-background rounded rounded-b-none': show}">
              <h5 class="lg:flex country-colour  mb-0 px-3 py-3 w-16 md:w-32 font-medium items-center" :class="{'flex':otherSites.length, 'hidden': !otherSites.length}" v-if="!noName" v-on:click="reveal">
                  <span class="hidden lg:flex">{{name}}</span>
                  <span v-if="otherSites.length" class="inline lg:hidden">{{ strip(lang) }}</span>
                  <img v-if="otherSites.length" src="/assets/images/globals/down-arrow.svg" alt="" class="w-4 h-4 ml-2 md:ml-4">
              </h5>

              <div v-if="show" class="fixed inset-0 h-screen" v-on:click="hide">
              </div>
              <div v-if="show" class="text-bold country-colour absolute switcher-background rounded rounded-t-none border-t border-white" style="top:100%; z-index:40">
                  <a v-for="site in otherSites" :href="site.redirect" class="country-colour font-medium ">
                      <h5 class="mb-0 px-3 py-3 w-16 md:w-32">
                          <span class="hidden lg:inline">{{ site.name }}</span>
                          <span v-if="otherSites.length" class="inline lg:hidden">{{ strip(site.lang) }}</span>
                      </h5>
                  </a>
              </div>
          </div>
      </span>

    </div>
</template>

<script>
export default {
    props: {
        noName: Boolean,
        otherSites: Array,
        logo: String,
        url: String,
        alt: String,
        lang: String,
        name: String,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        reveal() {
            if (this.otherSites.length)
            this.show = true;
        },
        hide() {
            this.show = false;
        },
        strip(lang) {
            return lang.split("-")[0]
        }
    }
}
</script>

<style>

.switcher-background {
    background-color: rgba(255, 255, 255, 0.2);
}
</style>
